<template>
  <div id="reference-parts-sidebar-wrap" class="d-flex" :style="style">
    <div id="reference-parts-sidebar" v-show="open">
      <v-row class="header ma-0">
        <v-col cols="11" class="pa-0 mt-2" align="center">
          <!-- タブ -->
          <v-tabs
            v-model="tab"
            height="30"
            background-color="transparent"
            light
            centered
            show-arrows
          >
            <v-tabs-slider color="grey lighten-5"></v-tabs-slider>
            <v-tab
              v-for="category in categories"
              :key="category.id"
              class="tab-box text-caption pa-0"
              active-class="tab-box_active"
            >
              {{ category.name }}
            </v-tab>
          </v-tabs>
          <!-- タブ内アイテム -->
          <v-tabs-items v-model="tab" class="tab-items">
            <v-tab-item v-for="category in categories" :key="category.id">
              <!-- パーツ一覧 -->
              <v-chip-group
                v-if="currentCategoryItems(category.id).length"
                column
                class="parts-chips pl-2 ma-1"
                active-class="indigo darken-1"
              >
                <div
                  v-for="section in currentCategoryItems(category.id)"
                  :key="section.sectionId"
                >
                  <v-chip
                    small
                    color="#7D9BCC"
                    class="white--text"
                    @click="currentSection = section"
                    v-if="$route.params.partsName !== section.dataRef"
                  >
                    {{ section.label }}
                  </v-chip>
                </div>
              </v-chip-group>
              <!-- 表示するパーツがない場合 -->
              <div v-else class="text-caption py-3 px-2">
                {{ $store.getters.msgNoData }}
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!-- アコーディオン開閉ボタン -->
        <v-col cols="1" class="py-3" align="right">
          <v-btn icon @click="onClickFullExpand" color="blue darken-4">
            <v-icon v-show="!fullExpand">mdi-chevron-double-right</v-icon>
            <v-icon v-show="fullExpand">mdi-chevron-double-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="parts-wrap px-8 py-4">
        <div
          :class="
            'parts d-flex ' + (fullExpand ? 'justify-center' : 'justify-start')
          "
        >
          <section-resolver
            v-if="dataInit"
            :elementId="currentSection.dataRef"
            :name="strategyPartsMetaDict[currentSection.dataRef].comp"
            :inputStatus="currentSection.inputStatus"
            :ref="currentSection.name"
            :hasWritePermission="false"
            :dataInit="dataInit"
            ref-mode
            :ref-full-expand="fullExpand"
            v-model="value[this.partsMeta.inputDataKey]"
            @update="needUpdate = true"
          ></section-resolver>
        </div>
      </div>
    </div>
    <div class="open-btn" v-ripple @click="onClickOpen">
      <v-icon color="white" class="pb-2">mdi-file-eye-outline</v-icon>
      戦略パーツを見る
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import strategyPartsMetaDict, {
  StrategyPartsMetaDictItem,
} from "../../../utils/strategyPartsMetaDict";
import SectionResolver from "@/components/util/SectionResolver";

export default {
  components: {
    SectionResolver,
  },
  data() {
    return {
      open: false,
      fullExpand: false,
      dataInit: false,
      currentSection: null,
      strategyPartsMetaDict,
      value: null,
      partsMeta: new StrategyPartsMetaDictItem(),

      tab: 0,
      categories: [
        { id: 0, name: "BPシート" },
        { id: 1, name: "フレームワーク" },
        { id: 2, name: "その他" },
        { id: 3, name: "仮カテゴリ４" },
        { id: 4, name: "仮５" },
        { id: 5, name: "仮６" },
      ],
    };
  },
  computed: {
    ...mapGetters(["registeredSections", "hasWritePermission"]),
    style() {
      if (this.fullExpand & this.open) {
        return { width: "100%", minWidth: "none" };
      }
      if (this.open) {
        return { width: "600px", minWidth: "600px" };
      }
      return { width: 0, minWidth: 0 };
    },
    sections() {
      return this.registeredSections?.sections.map((section) => {
        return { ...section.section, inputStatus: section.inputStatus };
      });
    },
    currentCategoryItems() {
      return function (categoryId) {
        return this.sections.filter((item) => item.category == categoryId);
      };
    },
  },
  async created() {
    // セクションの一覧を取得
    if (!this.registeredSections) {
      await this.getRegisteredSections();
    }
  },
  methods: {
    ...mapActions(["getRegisteredSections"]),

    onClickFullExpand() {
      this.fullExpand = !this.fullExpand;
      const val = this.fullExpand & this.open;
      this.$emit("click-expand", val);
    },
    onClickOpen() {
      this.open = !this.open;
      const val = this.fullExpand & this.open;
      this.$emit("click-expand", val);
    },
  },
  watch: {
    async currentSection(to) {
      if (!to) return;

      // メタ情報辞書から戦略パーツメタ情報を取得する
      this.partsMeta = strategyPartsMetaDict[to.dataRef];

      // データを取得する
      await this.$store.dispatch(this.partsMeta.fetchAction);
      this.value = this.$store.getters[this.partsMeta.getterKey];
      this.dataInit = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#reference-parts-sidebar-wrap {
  position: sticky;
  top: 0;
  height: calc(100vh - 125px);
  z-index: 100;
  transition: width 300ms 0s ease;
  transition-property: width, min-width, max-width;
  #reference-parts-sidebar {
    max-width: calc(100% - 50px);
    min-width: calc(100% - 50px);
    background-color: #f1f7ff;
    border-left: solid 6px $bt-primary;
    border-right: solid 6px $bt-primary;
    display: flex;
    flex-direction: column;
    .header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 5;
      background-color: #f1f7ff;
      box-shadow: 0px 2px 2px 0px #bdbdbd;
    }
    .tab-box {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      min-width: 130px !important;
      max-width: 360px !important;
      background-color: #E0E0E0;
    }
    .tab-box_active {
      background-color: #757575 !important;
      color: #fff;
    }
    .tab-items {
      background-color: transparent !important;
    }
    .parts-wrap {
      width: 100%;
      height: 100%;
      overflow-x: auto;
      overflow-y: auto;

      @include scrollAreaY();
      @include scrollAreaX();
    }
  }
  .open-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: #0761b4;
    color: white;
    width: 50px;
    height: fit-content;
    padding: 10px;
    margin-top: 100px;
    border-radius: 0 15px 15px 0;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
#reference-parts-sidebar-wrap {
  .parts-chips {
    .v-slide-group__content {
      justify-content: center !important;
    }
  }
  .parts {
    .ref-mode {
      // 参照エリア見た目調整用
      .v-text-field--outlined {
        fieldset {
          // 外枠非表示
          border: none;
        }
        .v-label--active {
          // ラベルの位置調整
          color: black;
          transform: translateY(-40px) translateX(-12px) scale(0.75);
        }
        .v-label--active::before {
          // ラベル行頭に「・」表示
          content: "・";
        }
        .v-input__slot {
          margin-top: 20px !important;
          background-color: #eceff1 !important;
        }
        .v-input__append-inner {
          // セレクトボックスの▼を非表示
          display: none;
        }
      }
    }
  }
}
</style>